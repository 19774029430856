import './Landing.css';
import React from "react";
import logo from '../assets/images/wsf.svg'

function Landing() {

    return (
        <div className="landing">
            <img src={logo} className="landingLogo" alt="logo" />
            <div className="accentLine leftLine"></div>
            <div className="accentLine rightLine"></div>
            <p className="landingWelcomeHeaderText"> WELCOME TO</p>
            <p className="landingHeaderText"> WORK SMARTER FITNESS</p>
            <p className="landingDescriptionText"> A NEURAL NETWORK TRAINED TO PLAN THE BEST CUSTOM WORKOUT FOR EVERY INDIVIDUAL</p>
            <div className="landingSignUpButton">
                <p className="landingDescriptionText"> SIGN UPS CURRENTLY CLOSED, CHECK BACK SOON</p>
            </div>
        </div>
    )
}

export default Landing;