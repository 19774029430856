import './App.css';
import React, { Component } from "react";
// import Home from './pages/Home.js'
import Landing from './pages/Landing.js'


class App extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render () {
    const { width } = this.state;
    const isMobile = width <= 500;
    const appClass = isMobile ? "AppMobile" : "App";

      return (
        <div className={appClass}>
          <Landing/>
        </div>
      );
    }
}

export default App;
